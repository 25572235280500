import { inject, Injectable } from '@angular/core';
import { CookieService, LocalstorageService } from 'ngx-unificator/services';
import { combineLatest, of } from 'rxjs';
import { catchError, first, map, shareReplay, tap } from 'rxjs/operators';
import { CmsApiService } from '../services/api/cms-api.service';
import { SsApiService } from '../services/api/ss-api.service';
import { GoogleTagManagerService } from '../services/google-tag-manager.service';
import { UserService } from '../services/user/user.service';
import { AB_TEST_LIST, ABTestTaskListType, ABVariantsType } from './ab-test.data';
import { createABTestDebugMessage } from './ab-test-debug';
import { isDesktop, isTablet } from '../helpers/device';

@Injectable({
  providedIn: 'root'
})
export class AbTestNewService {

  private _api = inject(SsApiService);
  private _cmsApi = inject(CmsApiService);
  private _cookie = inject(CookieService);
  private _gtm = inject(GoogleTagManagerService);
  private _user = inject(UserService);
  private _storage = inject(LocalstorageService);

  /**
   * The function `loadABTest$` loads an AB test value from a CMS API and handles caching and error
   * handling.
   * @param {ABTestTaskListType} abTestTask - The `abTestTask` parameter in the `loadABTest$` function
   * represents the type of AB test task that needs to be loaded. It is used to determine which AB test
   * configuration to fetch and apply.
   * @returns The `loadABTest$` function returns an Observable that emits the resolved value of an AB
   * test.
   */
  public loadABTest$(abTestTask: ABTestTaskListType) {
    const abTest = AB_TEST_LIST[abTestTask];

    if (abTest?.resolvedValue) {
      abTest.resolvedValue$?.next(abTest.resolvedValue);
      return abTest.resolvedValue$.asObservable();
    }

    const _headers = this._createHeaders(abTest?.headerMainGroup);
    const resolvedValue$ = this._cmsApi.loadABTest(null, {headers: _headers}).pipe(
      map((e: { data: any[] }) => e.data?.[0]?.slug ?? null),
      tap((abTestValue: ABVariantsType) => this._handleResolvedValue(abTest, abTestTask, abTestValue)),
      catchError(() => {
        abTest.resolvedValue$.next(null);
        return of(null);
      }),
      shareReplay(1)
    );

    resolvedValue$.pipe(first()).subscribe();

    return resolvedValue$;
  }

  /**
   * Add user to AB test group after registration
   */
  private _addUserToABTestGroup(prefix: ABVariantsType) {
    return this._api.postPlayerGroups({groups: {add: [prefix]}}).pipe(
      first(),
      catchError(error => of(error))
    ).subscribe(() => {
      this._user.info.statuses.push({name: '', id: prefix})
    });
  }

  /**
   * Check if user has one of A or B test group, by ab test list
   * @param userStatusList
   * @param abTestIdList
   */
  public checkExistUserGroups(abTestIdList: any[]): string | null {
    const userStatuses = this._user?.info?.statuses ?? [];
    const matchingStatus = userStatuses.find(status => abTestIdList.includes(status?.id));
    return matchingStatus?.id ?? null;
  }

  /**
   * Synchronizes the user's AB test group on authentication.
   * This method checks the user's group for each AB test in the AB_TEST_LIST,
   * and adds the user to the appropriate AB test group if they match one of the variants.
   */
  public syncUserABTestGroupOnAuth() {
    const allTestList$ = Object.keys(AB_TEST_LIST).map(abTestTask => {
      const abTest = AB_TEST_LIST[abTestTask];

      return abTest?.resolvedValue$?.pipe(
        tap((group: ABVariantsType) => {
          const userGroup = this.checkExistUserGroups(abTest?.abTestVariants);
          if (abTest?.abTestVariants?.includes(group)) {
            if (!userGroup && abTest?.syncOnAuth) {
              this.assignUserToAbTestGroup(abTestTask, group);
            }
            if (userGroup && abTest?.resolvedCookieValue !== userGroup) {
              this._cookie.delete(abTest?.resolvedCookieValue, '/');
              this._cookie.set(userGroup, userGroup, 999, '/');
              abTest.resolvedValue = userGroup as ABVariantsType;
              abTest.resolvedValue$.next(userGroup as ABVariantsType);
              if (abTest?.debug) {
                createABTestDebugMessage(this._storage, abTestTask, `user already has group ${userGroup}, cookie ${group} moved to ${userGroup}`);
              }
            }
          }
        })
      );
    });

    combineLatest(allTestList$).subscribe();
  }

  /**
   * Creates headers for the AB test request.
   * @param headerMainGroup - The main group header.
   * @returns The headers object.
   */
  private _createHeaders(headerMainGroup: string) {
    return {
      'Content-Type': 'application/json',
      'u-group': headerMainGroup,
      'DEVICE-TYPE': isDesktop() ? 'Desktop' : isTablet() ? 'Tablet' : 'Mobile'
    };
  }

  /**
   * Handles the resolved value from the AB test request.
   * @param abTest - The AB test object.
   * @param abTestTask - The AB test task.
   * @param abTestValue - The resolved value from the AB test request.
   */
  private _handleResolvedValue(abTest: any, abTestTask: ABTestTaskListType, abTestValue: ABVariantsType) {
    if (abTestValue) {
      this._cookie.set(abTestValue, abTestValue, 999, '/');
      this._gtm.setAbTest('ab-test', abTestTask, abTestValue);
      abTest.resolvedValue = abTestValue;
      abTest.resolvedCookieValue = abTestValue;
      abTest.resolvedValue$.next(abTestValue);
      if (abTest.debug) {
        createABTestDebugMessage(this._storage, abTestTask, `value resolved from http ${abTestValue}`);
      }
      this._rewriteForAuthUserCookies(abTest, abTestTask);
    }
  }

  /**
   * Rewrites the A/B test cookies for an authenticated user.
   *
   * This method checks the user's existing groups and updates the resolved A/B test
   * value and cookie accordingly. If debugging is enabled, a debug message is created.
   *
   * @param {object} abTest - The A/B test object.
   * @param {string} abTestTask - The A/B test task identifier.
   */
  private _rewriteForAuthUserCookies(abTest: any, abTestTask: ABTestTaskListType) {
    if (this._user?.info?.statuses) {
      const userGroup = this.checkExistUserGroups(abTest?.abTestVariants);
      const cookieGroup = abTest?.resolvedValue;
      if (userGroup && abTest?.resolvedCookieValue !== userGroup) {
        this._cookie.delete(abTest?.resolvedCookieValue, '/');
        this._cookie.set(userGroup, userGroup, 999, '/');
        abTest.resolvedValue = userGroup as ABVariantsType;
        abTest.resolvedValue$.next(userGroup as ABVariantsType);
        if (abTest?.debug) {
          createABTestDebugMessage(this._storage,abTestTask, `user already has group ${userGroup}, cookie ${cookieGroup} moved to ${userGroup}`);
        }
      }
    }
  }

  /**
   * Assigns a user to a specified A/B test group.
   *
   * This method sets the A/B test group for the given task and user,
   * adds the user to the specified A/B test group, and updates the resolved
   * value of the A/B test. If debugging is enabled, a debug message is created.
   *
   * @param {string} abTestTask - The A/B test task identifier.
   * @param {object} abTest - The A/B test object.
   * @param {string} group - The group identifier to assign the user to.
   */
  public assignUserToAbTestGroup(abTestTask, group) {
    const abTest = AB_TEST_LIST[abTestTask];
    this._addUserToABTestGroup(group);
    abTest.resolvedValue = group;
    if (abTest?.debug) {
      createABTestDebugMessage(this._storage, abTestTask, `added ab test group ${group} to user`);
    }
  }
}
